<template>
  <div class="o-shipping">
    <SfHeading
      :title="`${$t('Shipping')}`"
      :level="2"
      class="sf-heading--left sf-heading--no-underline title"
    />
    <div class="form">
      <div v-if="shippingMethods.length > 0" class="form__radio-group">
        <SfRadio
          v-for="method in shippingMethods"
          :key="method.method_code"
          v-model="shipping.shippingMethod"
          :value="method.method_code"
          :disabled="isCartBusy"
          name="shipping-method"
          class="form__radio shipping"
          @input="changeShippingMethod({ skipPullingShippingMethods: true })"
        >
          <template #label>
            <div class="sf-radio__label shipping__label">
              <div class="shipping__info">
                <div class="shipping__title">
                  {{ method.method_title }}
                </div>
                <div class="shipping__subtitle" v-if="method.extension_attributes">
                  {{ method.extension_attributes.option_sub_title }}
                </div>
              </div>
              <div class="shipping__label-price">
                {{ labelPrice(method.amount) }}
              </div>
            </div>
            <div class="shipping__caption" v-if="method.extension_attributes">
              <div v-if="Array.isArray(method.extension_attributes.logotype_url)">
                <SfImage
                  v-for="(logotype_url, i) in method.extension_attributes.logotype_url"
                  :key="i"
                  :src="logotype_url"
                  class="shipping__carrier-logo"
                />
              </div>
              <SfImage v-else class="shipping__carrier-logo" :src="method.extension_attributes.logotype_url" />
              {{ method.extension_attributes.description_caption }}
            </div>
            <div class="shipping__pick-up" v-if="isSelectedMethodPickup(method.method_code)">
              <transition name="fade">
                <SfComponentSelectExtend
                  v-model="selectedPickupPoint"
                  v-show="selectedPickupPoint"
                  ref="pickUp"
                  :disabled="isCartBusy"
                  class="pick-up__select pick-up"
                >
                  <SfComponentSelectOption
                    v-for="option in pickupPoints"
                    :key="option.agentId"
                    :value="option.agentId"
                    class="pick-up__option"
                  >
                    <div>
                      <strong>{{ option.name }}</strong>
                      <div> {{ option.address }} </div>
                    </div>
                    <SfImage
                      :src="option.logotypeUrl"
                      class="shipping__carrier-logo"
                    />
                  </SfComponentSelectOption>
                </SfComponentSelectExtend>
              </transition>
            </div>
          </template>
        </SfRadio>
      </div>
      <div v-else class="shipping-methods__loader">
        <ASpinner />
      </div>
      <SfAlert
        v-if="shippingError"
        :message="shippingError"
        type="danger"
        class="shipping-error"
      />
      <!-- <APromoCode name="couponCode" class="smartphone-only" /> -->
      <div class="form__action">
        <AButtonDivided
          class="sf-button--full-width color-primary form__action-button"
          :label2="prices.grand_total | price"
          :disabled="isCheckoutDisabled"
          @click="sendDataToCheckout"
        >
          <template #label1>
            <span class="desktop-only"> {{ $t('Continue to checkout') }} </span>
            <span class="smartphone-only"> {{ $t('Checkout') }} </span>
          </template>
        </AButtonDivided>
      </div>
      <MPriceSummary
        v-if="isActive"
        class="smartphone-only"
        is-large
      />
    </div>
  </div>
</template>
<script>
import config from 'config';
import { mapGetters, mapActions } from 'vuex';
import { Logger } from '@vue-storefront/core/lib/logger';
import { Shipping } from '@vue-storefront/core/modules/checkout/components/Shipping';
import { SfCheckbox, SfInput, SfHeading, SfAlert, SfImage, SfRadio } from '@storefront-ui/vue';
import SfComponentSelectExtend from '~/theme/components/molecules/m-component-select';
import { createSmoothscroll } from '~/theme/helpers';
import ASpinner from '~/theme/components/atoms/a-spinner';
// import APromoCode from '~/theme/components/atoms/a-promo-code';
import AButtonDivided from '~/theme/components/atoms/a-button-divided';
import MPriceSummary from '~/theme/components/molecules/m-price-summary';
import ShippingLogtrade from '~/theme/mixins/shipping-logtrade.js';
import { price } from '@vue-storefront/core/filters';

export default {
  name: 'OShipping',
  components: {
    SfRadio,
    SfImage,
    SfComponentSelectExtend,
    SfHeading,
    SfAlert,
    ASpinner,
    // APromoCode,
    AButtonDivided,
    MPriceSummary
  },
  mixins: [Shipping, ShippingLogtrade],
  validations: {
    shipping: {
    }
  },
  data () {
    return {
      shippingError: null,
      isShippingLoading: false
    }
  },
  computed: {
    ...mapGetters({
      isCartBusy: 'cart/getCartProcessingStatus',
      totals: 'cart/getTotals',
      shippingMethods: 'checkout/getShippingMethods',
      shippingMethod: 'cart/getShippingMethod'
    }),
    prices () {
      const segmentPrices = this.totals.reduce((result, price) => {
        result[price.code] = price.value;
        return result;
      }, {});
      if (this.shippingMethods && this.shippingMethod) {
        const shippingMethodDetails = this.shippingMethods.find(method =>
          method.method_code === this.shippingMethod.method_code)
        if (shippingMethodDetails) {
          const currentShippingPriceIncludedInGrandTotals =
            this.$store.state.cart.platformTotals?.shipping_incl_tax || 0
          segmentPrices.shipping_incl_tax = shippingMethodDetails.price_incl_tax
          segmentPrices.grand_total += segmentPrices.shipping_incl_tax - currentShippingPriceIncludedInGrandTotals
        }
      }
      return segmentPrices
    },
    isCheckoutDisabled () {
      return this.$v.shipping.$invalid || !this.shipping.shippingMethod || !this.shippingMethods.length || this.isCartBusy || this.isShippingLoading
    }
  },
  watch: {
    shippingMethods: {
      handler () {
        if (!this.getCurrentShippingMethod()) {
          this.changeShippingMethod({ skipPullingShippingMethods: false })
        }
      },
      deep: true
    }
  },
  methods: {
    ...mapActions('cart', {
      setShippingMethodInStore: 'setShippingMethod',
      getShippingMethod: 'getShippingMethod'
    }),
    ...mapActions('logtrade', {
      selectPickupPoint: 'selectPickupPoint'
    }),
    getCurrentShippingMethod () { // Override method from core mixin
      const shippingCode = this.shipping.shippingMethod
      return this.shippingMethods?.find(_ => _.method_code === shippingCode) || this.getShippingMethod || null
    },
    checkDefaultShippingMethod () { // Override method from core mixin
      if (config.shipping.use_default_method === true) {
        if (!this.shipping.shippingMethod || this.notInMethods(this.shipping.shippingMethod)) {
          let shipping = this.shippingMethods.find(item => item.default)
          if (!shipping && this.shippingMethods && this.shippingMethods.length > 0) {
            shipping = this.shippingMethods[0]
          }
          this.shipping.shippingMethod = shipping.method_code
          this.shipping.shippingCarrier = shipping.carrier_code
        }
      }
      if (!this.shipping.shippingMethod && this.shippingMethods.length > 0) {
        this.shipping.shippingMethod = this.shippingMethods[0]?.method_code || ''
      }
      if (!this.getCurrentShippingMethod()) {
        this.shipping.shippingMethod = (this.shippingMethods || [])[0]?.method_code || ''
      }
    },
    async changeShippingMethod ({ skipPullingShippingMethods, sendToMagento }) {
      this.checkDefaultShippingMethod()
      const { shipping, shippingMethods } = this
      const currentShippingMethod = this.getCurrentShippingMethod()
      if (!currentShippingMethod) {
        Logger.error('Cannot find shipping method', 'Logtrade', { shipping, shippingMethods })()
        return
      }
      this.shipping = {
        ...shipping,
        shippingMethod: currentShippingMethod.method_code,
        shippingCarrier: currentShippingMethod.carrier_code
      }
      const currentPaymentMethod = this.paymentMethod.find(_ => _.code === 'collectorbank_checkout')?.code || this.paymentMethod[0]?.code
      const shippingMethodPayload = {
        country: this.shipping.country,
        method_code: this.shipping.shippingMethod,
        carrier_code: this.shipping.shippingCarrier,
        payment_method: currentPaymentMethod,
        skipPullingShippingMethods
      }
      this.setShippingMethodInStore({ shippingMethod: shippingMethodPayload })

      if (sendToMagento) {
        this.selectPickupPoint(this.selectedPickupPoint)
        await this.$store.dispatch('cart/syncTotals', { forceServerSync: true, methodsData: shippingMethodPayload, skipPullingShippingMethods: skipPullingShippingMethods })
        // this.shippingMethod = shippingMethodPayload
      }
      await this.onShippingMethodChanged()
    },
    async onAfterPersonalDetails () {
      if (this.isShippingLoading === false) {
        this.isShippingLoading = true
        await this.updateShippingMethods()
        await this.changeShippingMethod({ skipPullingShippingMethods: true })
        this.isShippingLoading = false
      }
    },
    async updateShippingMethods () {
      await this.$store.dispatch('cart/syncShippingMethods', { forceServerSync: true })
    },
    async sendDataToCheckout () {
      await this.changeShippingMethod({ skipPullingShippingMethods: true, sendToMagento: true })
      this.shippingError = null // TODO: error handling could be moved directly to vuelidate for better consistency
      if (this.shippingError) return
      this.$bus.$emit('checkout-after-shippingDetails', this.shipping, this.$v)
      this.isFilled = true
    },
    labelPrice (amount) {
      return (amount > 0) ? price(amount) : this.$i18n.t('Free')
    }
  },
  mounted () {
    this.onAfterPersonalDetails()
    createSmoothscroll(document.documentElement.scrollTop || document.body.scrollTop, 0);
  }
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.shipping-methods__loader {
  padding: 0 0 var(--spacer-lg);
  width: 100%;
  display: flex;
  justify-content: center;
}

.shipping-error {
  padding: var(--spacer-sm) 0 var(--spacer-sm) calc(var(--spacer-sm) * 1.1);
}

::v-deep .sf-radio, ::v-deep .shipping {
  --radio-container-padding: var(--spacer-xs) var(--spacer-2xs);
  @include for-desktop {
    --radio-container-padding: var(--spacer-xs) var(--spacer-sm);
  }

  &.is-active {
    background: transparent;
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--c-light-variant);
  }

  &__content {
    --radio-content-margin: 3px 0 0 var(--spacer-sm);
  }

  &__checkmark {
    --radio-checkmark-border-color: var(--c-primary);

    &.is-active {
      --radio-checkmark-border-color: var(--c-primary);
    }

    .is-disabled & {
      --radio-checkmark-border-color: var(--c-light);
    }
  }

  &__label {
    display: flex;
    justify-content: flex-start;

    &-price {
      font-size: var(--font-size--lg);
      @include for-mobile {
        margin-left: auto;
      }
    }
  }

  &__title {
    font-weight: var(--font-weight--semibold);
    font-size: var(--font-size--base);
    @include for-mobile {
      font-size: var(--font-size--sm);
    }
  }

  &__subtitle {
    padding-top: var(--spacer-2xs);
    font-size: var(--font-size--sm);
    @include for-mobile {
      font-size: var(--font-size--xs);
    }
  }

  &__caption {
    color: var(--c-text-muted);
    padding-top: var(--spacer-xs);
    font-size: var(--font-size--xs);

    .is-disabled & {
      color: var(--c-light);
    }

    @include for-desktop {
      font-size: var(--font-size--sm);
    }
  }

  &__carrier-logo {
    --image-height: 10px;
    @include for-desktop {
      --image-height: 18px;
    }
    float: right;

    &:not(:last-child) {
      padding-left: var(--spacer-xs);
    }

    .is-disabled & {
      opacity: 0.2;
      filter: grayscale(1);
    }
  }

  &__description {
    --radio-description-margin: 0;
  }

  &__delivery {
    color: var(--c-text-muted);
    display: flex;
  }

  &__action {
    @include for-mobile {
      margin: 0 0 0 var(--spacer-xs);
    }

    &::before {
      content: "+";
    }

    .is-active {
      --button-color: var(--c-primary);
      --button-transition: color 150ms linear;

      &::before {
        content: "-";
      }
    }
  }

  @include for-desktop {
    &__label {
      justify-content: space-between;
    }
    &__delivery {
      justify-content: space-between;
      max-width: 240px;
    }
  }

  .sf-component-select, .pick-up {
    padding: 0;
    background: var(--c-light-variant);

    &.is-active {
      --chevron-color: var(--c-primary);
      --component-select-border-color: var(--c-primary);
    }

    &__dropdown {
      height: 100%;
      .sf-component-select__options {
        height: calc(100% - 52px);
      }

      @include for-desktop {
        height: auto;
        overflow-y: scroll;
        max-height: 240px;
        .sf-component-select__options {
          height: auto;
        }
      }
    }

    &__selected {
      margin: var(--spacer-xs) 0 0 0;
      padding: var(--spacer-xs);
      font-size: var(--font-size--sm);
      justify-content: space-between;

      .shipping__carrier-logo {
        @include for-desktop {
          margin-right: 2rem;
        }
      }
    }

    &__option {
      justify-content: space-between;
    }

    &__error-message {
      min-height: 0;
    }
  }
}
</style>
