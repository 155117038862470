<template>
  <div class="o-payment">
    <PaypalButton />
    <!-- This dummy container below is needed because src\modules\payment-cash-on-delivery\index.ts
         tries to inject here a component with payment description -->
    <div v-show="false" id="checkout-order-review-additional-container" />
  </div>
</template>
<script>
import { Payment } from '@vue-storefront/core/modules/checkout/components/Payment';
import { createSmoothscroll } from '~/theme/helpers';
import PaypalButton from 'src/modules/paypal/components/Button'

export default {
  name: 'OPayment',
  components: {
    PaypalButton
  },
  mixins: [Payment],
  mounted () {
    createSmoothscroll(document.documentElement.scrollTop || document.body.scrollTop, 0);
  }
};
</script>
