<template>
  <div class="o-user-details">
    <div class="form">
      <div class="form__row">
        <div class="form__col">
          <label>{{ $t('First Name') }} *</label>
          <p>({{ $t('for order confirmation & delivery notification etc') }})</p>
          <SfInput
            v-model.trim="userDetails.firstName"
            class="form__element"
            name="first-name"
            :placeholder="$t('Enter your first name')"
            required
            :valid="!$v.userDetails.firstName.$error"
            :error-message="firstNameError"
          />
        </div>
        <div class="form__col">
          <label>{{ $t('Last Name') }} *</label>
          <p>({{ $t('for order confirmation & delivery notification etc') }})</p>
          <SfInput
            v-model.trim="userDetails.lastName"
            class="form__element"
            name="last-name"
            :placeholder="$t('Enter your last name')"
            required
            :valid="!$v.userDetails.lastName.$error"
            :error-message="lastNameError"
          />
        </div>
      </div>
      <div class="form__row">
        <div class="form__col">
          <label>{{ $t('Street Address') }} *</label>
          <p>({{ $t('to be able to show shipping options') }})</p>
          <SfInput
            v-model.trim="userDetails.street"
            class="form__element"
            name="street"
            :placeholder="$t('Street Address')"
            required
            :valid="!$v.userDetails.street.$error"
            :error-message="streetError"
          />
        </div>
        <div class="form__col">
          <label>{{ $t('City') }} *</label>
          <p>({{ $t('to be able to show shipping options') }})</p>
          <SfInput
            v-model.trim="userDetails.city"
            class="form__element"
            name="city"
            :placeholder="$t('City')"
            required
            :valid="!$v.userDetails.city.$error"
            :error-message="cityError"
          />
        </div>
      </div>
    </div>
    <div :class="{ 'disabled': disabledPayPalBtn }">
      <div style="color: red;">
        {{ $t(message) }}
      </div>
      <div class="paypal-button" />
    </div>
  </div>
</template>

<script>
import { PaypalButton } from './PaypalButton'
import { required, minLength } from 'vuelidate/lib/validators';
import SfInput from '@storefront-ui/vue/src/components/atoms/SfInput/SfInput.vue';

export default {
  mixins: [PaypalButton],
  components: {
    SfInput
  },
  validations: {
    userDetails: {
      firstName: {
        minLength: minLength(2),
        required
      },
      lastName: {
        minLength: minLength(2),
        required
      },
      street: {
        minLength: minLength(2),
        required
      },
      city: {
        minLength: minLength(2),
        required
      }
    }
  },
  data () {
    return {
      additionalMethod: {},
      userDetails: {
        firstName: '',
        lastName: '',
        street: '',
        city: ''
      }
    }
  },
  computed: {
    firstNameError () {
      return !this.$v.userDetails.firstName.required
        ? this.$t('Field is required')
        : ''
    },
    lastNameError () {
      return !this.$v.userDetails.lastName.required
        ? this.$t('Field is required')
        : ''
    },
    streetError () {
      return !this.$v.userDetails.street.required
        ? this.$t('Field is required')
        : ''
    },
    cityError () {
      return !this.$v.userDetails.city.required
        ? this.$t('Field is required')
        : ''
    },
    disabledPayPalBtn () {
      return !(this.$v.userDetails.firstName.required && this.$v.userDetails.lastName.required && this.$v.userDetails.street.required && this.$v.userDetails.city.required);
    }
  },
  beforeMount () {
    this.$bus.$on('checkout-before-placeOrder', () => {
      this.$bus.$emit('checkout-do-placeOrder', this.additionalMethod)
    })
  },
  methods: {
    async onApprove (data, actions) {
      this.additionalMethod = {
        // magento 2 fields expects
        userDetails: this.userDetails,
        paymentMethodInfo: {
          paypal_express_checkout_token: this.token,
          button: 1,
          paypal_express_checkout_payer_id: data.payerID,
          paypal_express_checkout_redirect_required: false
        },
        paymentMethod: 'paypal_express'
      }
      await this.$bus.$emit('checkout-do-placeOrder', this.additionalMethod)
    },
    async renderButton () {
      window.paypal.Buttons({
        createOrder: await this.onCreateOrder,
        onApprove: this.onApprove,
        style: this.styling
      }).render('.paypal-button')
    },
    async onCreateOrder (data, actions) {
      const token = await this.$store.dispatch('payment-paypal-magento2/createOrder')
      return token
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
 .disabled {
  pointer-events: none;
  opacity: 0.5;
  max-width: 100%;
 }
  .button-busy {
    --loader-spinner-stroke: currentColor;
    background-color: var(--c-primary) ;
  }
  .o-user-details {
    &__additional-pruchase-info {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: var(--spacer-sm);
      margin-top: var(--spacer-sm);
      font-size: var(--font-size--sm);
      color: var(--c-text-secondary);
    }
    &__order-info-icon {
      display: inline;
      margin-right: var(--spacer-xs);
    }
    &__order-info-delivery-icon {
      ::v-deep {
        path {
          fill: transparent;
          stroke: var(--c-gray);
        }
      }
    }
    .form {
      &__row {
        display: flex;
        width: 100%;
        justify-content: space-between;
        @include for-mobile {
          flex-wrap:wrap;
        }
      }
      &__col {
        width:49%;
        flex: inherit;
        @include for-mobile {
          width: 100%;
          padding-bottom: 10px;
          &:last-child {
            padding-bottom:0px;
          }
        }
      }
    }
  }
</style>
<style lang="scss">
  @media only screen and (min-width: 1270px) {
    .paypal-button{
      transform: scale(1.11);
      transform-origin: 0 0;
    }
  }
  #first-name, #last-name, #street, #city {
    border: var(--input-border, var(--input-border-style, solid) var(--input-border-color, var(--c-link)));
    border-width: var(--input-border-width, 1px);
    padding:15px;
  }
  .o-user-details {
    .sf-input__wrapper, .sf-input {
      height: auto;
      min-height: auto;
      margin: 0;
    }
    label {
      font-weight: bold;
      font-size: var(--font-size--base);
    }
    p {
      margin:4px 0 10px 0;
      font-size: var(--font-size--sm);
    }
    .form {
      .sf-input {
        &__bar {
          display: none !important;
        }
        &__label {
          display: none !important;
        }
        &__error-message {
          padding-top: var(--spacer-2xs);
        }
      }
    }
  }
</style>
