<template>
  <div class="o-personal-details">
    <div class="form">
      <div class="form__row">
        <div class="form__col">
          <label>{{ $t('E-mail address') }} *</label>
          <p>({{ $t('for order confirmation & delivery notification etc') }})</p>
          <SfInput
            v-model.trim="personalDetails.emailAddress"
            class="form__element"
            name="email-address"
            :placeholder="$t('Enter your email address')"
            required
            :valid="!$v.personalDetails.emailAddress.$error"
            :error-message="emailAddressError"
            @blur="onEmailAddressBlur"
          />
        </div>
        <div class="form__col">
          <label>{{ $t('Postal code') }} *</label>
          <p>({{ $t('to be able to show shipping options') }})</p>
          <SfInput
            v-model.trim="shipping.zipCode"
            class="form__element"
            name="postal-code"
            :placeholder="$t('Enter your postcode (5 digits)')"
            type="text"
            inputmode="numeric"
            pattern="[0-9]*"
            required
            :valid="!$v.shipping.zipCode.$error"
            :error-message="zipCodeError"
            @keydown="parseZipcode"
            @blur="onZipCodeBlur"
            @keyup.enter="sendDataToCheckout"
          />
        </div>
      </div>
      <div class="form__row country-dropdown" v-if="checkoutCountries && Object.keys(checkoutCountries).length > 1">
        <div class="form__col">
          <label>{{ $t('Country') }} *</label>
          <SfSelect
            class="sf-select--underlined"
            name="country"
            :value="Object.values(checkoutCountries)[0]"
          >
            <SfSelectOption
              v-for="(value, index) in checkoutCountries"
              :key="index"
              :value="value"
              :selected="Object.values(checkoutCountries)[0] === value"
            >
              {{ value }}
            </SfSelectOption>
          </SfSelect>
        </div>
      </div>
      <SfInput
        v-else
        v-model.trim="shipping.country"
        name="country"
        type="hidden"
        required
      />
      <div class="form__summary">
        <MPriceSummary v-if="isActive" class="smartphone-only" :is-large="true" />
      </div>
      <div class="form__action">
        <!--  -->
        <SfButton
          class="sf-button--full-width form__action-button"
          :class="classlistUpdateBtn"
          :disabled="disabledUpdateBtn || isLoading"
          @click="sendDataToCheckout"
        >
          <ASpinner v-if="isLoading" :size="19" />
          <span v-else>
            {{ isActive ? $t("Continue") : $t("Update details") }}
          </span>
        </SfButton>
      </div>
    </div>
    <div class="o-personal-details__additional-pruchase-info" v-if="showEstimatedDelivery">
      <AIcon
        class="o-personal-details__order-info-icon o-personal-details__order-info-delivery-icon"
        size="xs"
        icon="delivery"
        color="grey"
      />
      <span class="desktop-only"> {{ productLabel || $t(defaultLabel) }} </span>
      <span class="smartphone-only"> {{ productLabel || $t(defaultLabel) }} </span>
    </div>
  </div>
</template>
<script>
import config from 'config';
import { mapGetters, mapActions, mapState } from 'vuex';
import { required, email, minLength, maxLength, numeric } from 'vuelidate/lib/validators';
import { PersonalDetails } from '@vue-storefront/core/modules/checkout/components/PersonalDetails';
import SfInput from '@storefront-ui/vue/src/components/atoms/SfInput/SfInput.vue';
import SfButton from '@storefront-ui/vue/src/components/atoms/SfButton/SfButton.vue';
import { SfSelect } from '@storefront-ui/vue';
import { ModalList } from '~/theme/store/ui/modals';
import ASpinner from '~/theme/components/atoms/a-spinner';
import MPriceSummary from '~/theme/components/molecules/m-price-summary';
import TrackingMixin from '~/theme/mixins/tracking/tracking.ts'
import AIcon from '~/theme/components/atoms/a-icon';
import { currentStoreView } from '@vue-storefront/core/lib/multistore';

export default {
  name: 'OPersonalDetails',
  components: {
    SfInput,
    SfButton,
    SfSelect,
    ASpinner,
    MPriceSummary,
    AIcon
  },
  props: {
    showEstimatedDelivery: {
      default: false,
      type: Boolean
    },
    initialLoad: {
      deafault: false,
      type: Boolean
    }
  },
  mixins: [PersonalDetails, TrackingMixin],
  validations: {
    personalDetails: {
      emailAddress: {
        required,
        email
      }
    },
    shipping: {
      zipCode: {
        required,
        minLength: config.shipping.checkZipCodeLength ? minLength(config.shipping.minZipCodeLength) : true,
        maxLength: config.shipping.checkZipCodeLength ? maxLength(config.shipping.minZipCodeLength) : true,
        numeric
      }
    }
  },
  data () {
    return {
      personalDetails: this.$store.state.checkout.personalDetails,
      shipping: this.$store.state.checkout.shippingDetails
    };
  },
  computed: {
    ...mapState({
      deliveryDate: state => state.ui.deliveryDate
    }),
    checkoutCountries () {
      return currentStoreView()?.checkout?.country ? currentStoreView().checkout.country : (config?.checkout?.country ? config.checkout.country : false);
    },
    ...mapGetters({
      isLoading: 'cart/getCartProcessingStatus',
      productsInCart: 'cart/getCartItems',
      totals: 'cart/getTotals',
      cartToken: 'cart/getCartToken',
      shippingMethods: 'checkout/getShippingMethods'
    }),
    defaultLabel () {
      return config.deliveryLabels.defaultLabel
    },
    emailAddressError () {
      return !this.$v.personalDetails.emailAddress.required
        ? this.$t('Field is required')
        : this.$t('Please provide valid e-mail address.')
    },
    zipCodeError () {
      return !this.$v.shipping.zipCode.required
        ? this.$t('Field is required')
        : this.$t('Please provide valid Zip-code.')
    },
    isZipCodeOrEmailChanged () {
      return (this.shipping.previousZipCode && this.shipping.previousZipCode !== this.shipping.zipCode) ||
        (this.personalDetails.previousEmail && this.personalDetails.previousEmail !== this.personalDetails.emailAddress)
    },
    isZipcodeLengthGreaterThanExpected () {
      return this.shipping.zipCode.length > config.shipping.minZipCodeLength
    },
    classlistUpdateBtn () {
      if (this.isLoading) return 'button-busy'
      if (this.isActive) return 'color-primary'
      if (!this.isActive && this.isZipCodeOrEmailChanged) return 'color-primary'
      return 'color-light'
    },
    disabledUpdateBtn () {
      return this.$v.personalDetails.$invalid ||
        this.$v.shipping.$invalid ||
        (!this.isActive && !this.isZipCodeOrEmailChanged) ||
        this.initialLoad ||
        this.isLoading
    },
    productLabel () {
      return this.deliveryDate?.desktop?.productLabel || ''
    },
    productLabelMobile () {
      return this.deliveryDate?.mobile?.productLabel || ''
    }
  },
  methods: {
    ...mapActions('ui', {
      openModal: 'openModal'
    }),
    ...mapActions('checkout', {
      savePersonalDetails: 'savePersonalDetails',
      saveShippingDetails: 'saveShippingDetails'
    }),
    isEmailValid () {
      return false;
    },
    login () {
      this.openModal({ name: ModalList.Auth, payload: 'login' })
    },
    openTermsAndConditionsModal () {
      this.openModal({ name: ModalList.TermsAndConditions })
    },
    onCheckoutLoad () {
      if (!this.$store.state.checkout.shippingDetails.country) {
        this.$store.state.checkout.shippingDetails.country = config.i18n.defaultCountry
      }
      this.personalDetails = Object.assign({}, this.personalDetails, this.$store.state.checkout.personalDetails)
      this.shipping = Object.assign({}, this.shipping, this.$store.state.checkout.shippingDetails)
    },
    onEmailAddressBlur () {
      this.$v.personalDetails.emailAddress.$touch()
      if (this.personalDetails.emailAddress && !this.$v.personalDetails.emailAddress.$error) {
        this.$bus.$emit('checkout-on-email-input', {
          products: this.productsInCart,
          coupon: this.$store.getters['cart/getCoupon'],
          totals: this.$store.getters['cart/getTotals']
        })
        this.savePersonalDetails(this.personalDetails)
        this.klaviyoCheckoutStarted()
      }
    },
    onZipCodeBlur () {
      this.$v.shipping.zipCode.$touch()
      this.saveShippingDetails(this.shipping)
    },
    klaviyoCheckoutStarted () {
      const totalPrice = this.totals.find(item => item.code === 'grand_total')?.value
      this.handleCheckoutStarted({ totalPrice, products: this.productsInCart, cartToken: this.cartToken })
    },
    parseZipcode (e) {
      console.log('Store code from within', currentStoreView());
      const allowListKeysRegex = /^([0-9]|ArrowLeft|ArrowRight|Backspace|Delete)$/
      const isCtrlA = e.key === 'a' && e.ctrlKey === true
      if (!allowListKeysRegex.test(e.key) && !isCtrlA) {
        e.preventDefault()
      }
      setTimeout(() => { // Hack, because on keydown parseZipcode doesn't know new value of `shipping.zipCode`
        if (this.isZipcodeLengthGreaterThanExpected) {
          this.shipping.zipCode = this.shipping.zipCode.slice(0, config.shipping.minZipCodeLength)
        }
      }, 0)
    },
    async sendDataToCheckout () {
      if (this.disabledUpdateBtn) return
      this.saveShippingDetails(this.shipping)
      this.savePersonalDetails(this.personalDetails)
      this.setCustomerEmailAsTrackingIndentifier({ email: this.personalDetails.emailAddress })

      this.personalDetails.previousEmail = this.personalDetails.emailAddress
      this.shipping.previousZipCode = this.shipping.zipCode

      this.$bus.$emit('checkout-after-personalDetails', this.personalDetails, this.$v, this.shipping)
      this.isFilled = true
      this.isValidationError = false
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
  .button-busy {
    --loader-spinner-stroke: currentColor;
    background-color: var(--c-primary) ;
  }
  .o-personal-details {
    &__additional-pruchase-info {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: var(--spacer-sm);
      margin-top: var(--spacer-sm);
      font-size: var(--font-size--sm);
      color: var(--c-text-secondary);
    }
    &__order-info-icon {
      display: inline;
      margin-right: var(--spacer-xs);
    }
    &__order-info-delivery-icon {
      ::v-deep {
        path {
          fill: transparent;
          stroke: var(--c-gray);
        }
      }
    }
    .form {
      &__row {
        display: flex;
        width: 100%;
        justify-content: space-between;
        @include for-mobile {
          flex-wrap:wrap;
          &.country-dropdown {
            margin-top: 10px;
          }
        }
      }
      &__col {
        width:49%;
        flex: inherit;
        @include for-mobile {
          width: 100%;
          padding-bottom: 10px;
          &:last-child {
            padding-bottom:0px;
          }
        }
      }
    }
  }
</style>
<style lang="scss">
  #email-address, #postal-code {
    border: var(--input-border, var(--input-border-style, solid) var(--input-border-color, var(--c-link)));
    border-width: var(--input-border-width, 1px);
    padding:15px;
  }
  .o-personal-details {
    .sf-input__wrapper, .sf-input {
      height: auto;
      min-height: auto;
      margin: 0;
    }
    label {
      font-weight: bold;
      font-size: var(--font-size--base);
    }
    p {
      margin:4px 0 10px 0;
      font-size: var(--font-size--sm);
    }
    .form {
      .sf-input {
        &__bar {
          display: none !important;
        }
        &__label {
          display: none !important;
        }
        &__error-message {
          padding-top: var(--spacer-2xs);
        }
      }
    }
  }
</style>
